import * as Sentry from "@sentry/sveltekit";
import {PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENVIRONMENT} from "$env/static/public"
import {version} from "$app/environment";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
    Sentry.init({
        dsn: PUBLIC_SENTRY_DSN ?? 'https://c89dcbc0166a49cfabdc38cd56f51254@glitch.mf1.us//2',
        environment: PUBLIC_SENTRY_ENVIRONMENT ?? 'production',
        release: version,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        integrations: [Sentry.replayIntegration()]

    });


    export const handleError = Sentry.handleErrorWithSentry();
